import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const isProduction = process.env.NODE_ENV === 'production'
const environment = process.env.REACT_APP_ENVIRONMENT

const configure = () => {
  if (isProduction) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN,
      environment,
      integrations: [new BrowserTracing()],
      maxBreadcrumbs: 50,
    })
  }
}

const setUserContext = user => {
  if (isProduction) {
    Sentry.configureScope(function(scope) {
      scope.setUser(user)
    })
  }
}

const report = (error, query) => {
  if (isProduction) {
    Sentry.withScope(scope => {
      scope.setTag('query', query)
      Sentry.captureException(new Error(error))
    })
  }
}

export default {
  configure,
  report,
  setUserContext,
}
